import React, { FC } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { SkipNavLink } from '@reach/skip-nav'
import { ReactComponent as NavLogo } from 'Assets/NavLogo.svg'
import { useAuth } from 'Auth/useAuth'
import styles from './Navigation.module.scss'
import { PlainButton } from 'UI/Button'
import { useFlashMessages } from './FlashMessages'
import Tooltip from '@reach/tooltip'
import VisuallyHidden from '@reach/visually-hidden'
import { useEndpoint } from 'Config/useEndpoint'
import '@reach/skip-nav/styles.css'
import '@reach/tooltip/styles.css'

const NavigationLink: FC<{ to: string }> = props => (
  <NavLink className={styles.link} activeClassName={styles.activeLink} {...props} />
)
const BottomLink: FC<{ to: string }> = props => (
  <NavLink className={styles.bottomLink} activeClassName={styles.bottomLinkActive} {...props} />
)

export const Navigation: FC = () => {
  const { isSignedIn, signOut } = useAuth()
  const navigate = useNavigate()
  const { addNoticeFlashMessage } = useFlashMessages()
  const { endpointDisplayName, endpoint } = useEndpoint()
  const environment = process.env.REACT_APP_DEPLOY_ENV
  const productionOrStaging = environment === 'production' || environment === 'staging'

  return (
    <div className={styles.navigation}>
      <SkipNavLink>Skip to content</SkipNavLink>
      <div className={styles.logoContainer}>
        <Link to='/' className={styles.logo}>
          <div className={styles.logoImageContainer}>
            <NavLogo aria-hidden />
          </div>
          <span aria-hidden className={styles.logoText}>
            CMS
          </span>
          <VisuallyHidden>ThinkCERCA Content Management System Home</VisuallyHidden>
        </Link>
        <div className={styles.currentEnvironment}>
          <span className={styles.currentEnvironmentLabel}>Connected to</span>
          <Tooltip label={endpoint}>
            <span>{endpointDisplayName()}</span>
          </Tooltip>
        </div>
      </div>
      <nav className={styles.nav}>
        <div className={styles.links}>
          {isSignedIn && (
            <>
              {/* <NavigationLink to='/subjects'>Subjects</NavigationLink> */}
              <NavigationLink to='/core-units'>Core Units</NavigationLink>
              <NavigationLink to='/curriculums/core'>Core Curriculums</NavigationLink>
              <NavigationLink to='/units'>Units</NavigationLink>
              <NavigationLink to='/library-units'>Units Library</NavigationLink>
              <NavigationLink to='/courses'>Courses</NavigationLink>
              <NavigationLink to='/standards'>Standards</NavigationLink>
              <NavigationLink to='/open-ai-generic-prompt'>Open AI Generic Prompt</NavigationLink>

              {!productionOrStaging && (
                <>
                  <NavigationLink to='/flexible-lessons'>Flexible Lessons</NavigationLink>
                  <NavigationLink to='/flexible-lesson-groups'>
                    Flexible Lesson Groups
                  </NavigationLink>
                  <NavigationLink to='/lessons'>Lessons</NavigationLink>
                </>
              )}
            </>
          )}
        </div>

        <div className={styles.bottomLinks}>
          <BottomLink to='/settings'>Settings</BottomLink>
          {isSignedIn ? (
            <PlainButton
              className={styles.bottomLink}
              onClick={() => {
                signOut()
                navigate('/sign-in', { replace: true })
                addNoticeFlashMessage('Successfully signed out')
              }}
            >
              Sign Out
            </PlainButton>
          ) : (
            <BottomLink to='/sign-in'>Sign In</BottomLink>
          )}
        </div>
      </nav>
    </div>
  )
}
